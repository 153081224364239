import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['collapsible', 'collapsibleCaret', 'alertIcon', 'requestForm']

  toggleCollapse() {
    const currentText = this.collapsibleCaretTarget.innerText
    const newText = currentText == 'expand_more' ? 'expand_less' : 'expand_more'
    this.collapsibleCaretTarget.innerText = newText
    this.collapsibleTarget.classList.toggle("collapsed")
  }

  hideImage() {
    this.alertIconTarget.classList.add('hidden')
  }

  submit(event) {
    event.preventDefault()
    Rails.fire(this.requestFormTarget, 'submit')
    this.hideImage()
  }
}
