import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['messagesContainer', 'newMessageInput', 'downloadMessageHistory']
  
  initialize() {
    this.messagesContainerTarget.scrollTo(0, this.messagesContainerTarget.scrollHeight);
  }

  sendToThirdParty(e){
    const sendToThirdParty = e.target.checked
    const link =  this.downloadMessageHistoryTarget
    const url = new URL(link.href);
    url.searchParams.set('send_to_third_party', sendToThirdParty);
    link.href = url.toString();
  }
}
