import { Controller } from "stimulus"
import A11yDialog from 'a11y-dialog'

export default class extends Controller {
  /*
    Add the following in the subclass to add callbacks for certain modal events.
    this.modal.on('show', () => {})
    this.modal.on('hide', () => {})
    this.modal.on('destroy', () => {})
    this.modal.on('create', () => {})
    More on https://a11y-dialog.netlify.app/advanced/events#instance-events
  */

  initialize() {
    this.modal = new A11yDialog(this.element)
  }

  openModal() {
    this.modal.show()
  }

  hideModal() {
    this.modal.hide()
  }
}
