import ModalController from "./modal_controller"

export default class extends ModalController {
  static targets = ['requestForm']

  fetchData(event) {
    const claimId = event.currentTarget.dataset.claimId
    fetch(`/claims/${claimId}/party_users`)
    .then((response) => response.text())
    .then((html) => {
      const fragment = document
        .createRange()
        .createContextualFragment(html)

      document.getElementById('user-permissions-modal-container').innerHTML = ""
      document.getElementById("user-permissions-modal-container").appendChild(fragment)
    })
  }

  submit(event) {
    event.preventDefault()
    Rails.fire(this.requestFormTarget, 'submit')
    this.hideModal()
  }
}
