import { Controller } from "stimulus"
import Emitter from "./events/emitter"
import SliderInputType from "./helpers/slider_input_type"

export default class extends Controller {
  static targets = ['slider', 'valueContainer', 'tooltip', 'roundNumber', 'previousRequest']

  static values = {
    requestType: String,
    nextMilestone: Number,
    previousRequests: Array,
    forBonusRound: Boolean,
    initialDemand: Number,
    initialOffer: Number,
  }

  connect() {
    this.type = SliderInputType.for(this.requestTypeValue)
    this.positionAndFormatPreviousRequests()
    this.positionAndFormatCurrentRequest()
    this.positionGradientAccordingToNextMilestone()

    Emitter.on('currencyInputChanged', ({ newRequestValue }) => {
      this.updateValue(newRequestValue)
      this.ensureRequestAmountDoesNotPassPreviousAmount()
      this.positionAndFormatCurrentRequest()
    })
    Emitter.emit('initialDemandValue', { initialDemandValue: this.initialDemandValue })
    Emitter.emit('initialOfferValue', { initialOfferValue: this.initialOfferValue })
  }

  onInput() {
    this.ensureRequestAmountDoesNotPassPreviousAmount()
    this.notifyChange()
    this.positionAndFormatCurrentRequest()
    document.getElementById("error-message").classList.remove("active")
  }

  /* Previous Requests */

  positionAndFormatPreviousRequests() {
    this.previousRequestTargets.forEach((previousRequestElement, index) => {
      const previousRequestValue = this.previousRequestsValue[index]

      this.updatePreviousRequest(previousRequestElement, previousRequestValue, index + 1)
    })
  }

  updatePreviousRequest(previousRequestElement, previousRequestValue, position) {
    this.positionPreviousRequest(previousRequestElement, this.valueAsPercentageGivenSliderRange(previousRequestValue))
    this.addOrdinalToPreviousRequest(previousRequestElement, position)
    this.formatPreviousRequestAmount(previousRequestElement, previousRequestValue)
  }

  positionPreviousRequest(previousRequestElement, offsetPercentage) {
    const { offsetSide, offsetPx } = this.type.offsetPxForPreviousRequest(offsetPercentage)

    previousRequestElement.style[offsetSide] = `calc(${offsetPercentage}% - ${offsetPx}px)`
  }

  addOrdinalToPreviousRequest(previousRequestElement, position) {
    previousRequestElement.querySelector('[data-role="previous-request-ordinal"]').innerHTML = this.numberWithOrdinal(position)
  }

  formatPreviousRequestAmount(previousRequestElement, amount) {
    const formatter = Intl.NumberFormat('en', {notation: 'compact', minimumFractionDigits: 1, maximumFractionDigits: 1})
    previousRequestElement.querySelector('[data-role="previous-request-amount"]').innerHTML = formatter.format(amount)
  }

  /* Current Request & Tooltip */

  positionAndFormatCurrentRequest() {
    const offsetPercentage = this.valueAsPercentageGivenSliderRange(this.currentValue())
    this.updateCurrentRound(offsetPercentage)

    if(!this.forBonusRoundValue) {
      this.updateTooltip(offsetPercentage)
    }
  }

  updateCurrentRound(offsetPercentage) {
    this.positionCurrentRound(offsetPercentage)
    this.updateCurrentRoundOrdinal()
  }

  positionCurrentRound(offsetPercentage) {
    const { offsetSide, offsetPx } = this.type.offsetPxForCurrentRound(offsetPercentage)

    this.valueContainerTarget.style[offsetSide] = `calc(${offsetPercentage}% + ${offsetPx}px)`
  }

  updateCurrentRoundOrdinal() {
    const currentRoundText = this.forBonusRoundValue ? 'Bonus' : this.numberWithOrdinal(this.previousRequestsValue.length + 1)
    this.roundNumberTarget.innerHTML = currentRoundText
  }

  updateTooltip(offsetPercentage) {
    this.positionTooltip(offsetPercentage)
    this.setTooltipMessageAndStyle()
  }

  positionTooltip(offsetPercentage) {
    const { offsetSide, offsetPx } = this.type.offsetPxForTooltip(offsetPercentage)

    this.tooltipTarget.style[offsetSide] = `calc(${offsetPercentage}% - ${offsetPx}px)`
  }

  setTooltipMessageAndStyle() {
    const { tooltipMessage, hasReachedMilestone } = this.type.tooltipStatusBasedOnMilestone(this.currentValue(), this.nextMilestoneValue)

    this.tooltipTarget.querySelector('[data-role="tooltip-text"]').innerHTML = tooltipMessage

    if(hasReachedMilestone) {
      this.tooltipTarget.classList.add('with-gradient')
    } else {
      this.tooltipTarget.classList.remove('with-gradient')
    }
  }

  positionGradientAccordingToNextMilestone() {
    const gradientMilestoneStop = this.type.gradientMilestoneStopFor(this.valueAsPercentageGivenSliderRange(this.nextMilestoneValue))

    this.element.style.setProperty('--gradient-milestone-stop', `${gradientMilestoneStop}%`)
  }

  /* Helpers */

  ensureRequestAmountDoesNotPassPreviousAmount() {
    const lastRequest = this.type.lastRequest(this.previousRequestsValue)

    if(this.type.hasValuePassedLastRequest(this.currentValue(), lastRequest)) {
      this.updateValue(lastRequest)
    }
  }

  valueAsPercentageGivenSliderRange(value) {
    const minValue = this.minValue()
    const maxValue = this.maxValue()
    const range = maxValue - minValue
    const correctedValue = value - minValue

    return (correctedValue * 100) / range
  }

  currentValue() {
    return this.sliderTarget.value
  }

  updateValue(value) {
    this.sliderTarget.value = value
  }

  minValue() {
    return Number(this.sliderTarget.min)
  }

  maxValue() {
    return Number(this.sliderTarget.max)
  }

  notifyChange() {
    Emitter.emit('sliderValueChanged', { newRequestValue: this.currentValue() })
  }

  // Taken from https://stackoverflow.com/a/31615643
  numberWithOrdinal(n){
    const s = ["th", "st", "nd", "rd"]
    const v = n % 100

    return n + (s[(v - 20) % 10] || s[v] || s[0])
  }
}
