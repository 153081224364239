import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["flashMessage"]

  connect() {
    this.observeFlashMessages()
    this.removeFlashMessages()
  }

  observeFlashMessages() {
    const config = { childList: true, subtree: true }

    this.observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          mutation.addedNodes.forEach(node => {
            if (node.matches && node.matches('[data-flash-message-target="flashMessage"]')) {
              this.removeFlashMessage(node)
            }
          })
        }
      }
    })

    this.observer.observe(this.element, config)
  }

  removeFlashMessages() {
    this.flashMessageTargets.forEach((flashMessage) => {
      if(flashMessage.classList.contains("failure")) return
      this.removeFlashMessage(flashMessage)
    })
  }

  removeFlashMessage(flashMessage) {
    setTimeout(() => {
      flashMessage.remove()
    }, 10000) // 10 seconds
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect()
    }
  }
}

// Ensure 'turbo-frame' is only registered once
if (!customElements.get('turbo-frame')) {
  customElements.define('turbo-frame', class extends HTMLElement {
    // Custom element logic here
  });
}
