import { Controller } from "stimulus";
import $ from "jquery";
import "select2";

export default class extends Controller {
  static targets = ["select"];

  // Local cache object to store the results
  cache = {};

  connect() {
    this.initializeSelect2();
  }

  initializeSelect2() {
    const self = this;

    // Initialize Select2 with AJAX for infinite scrolling and search
    $(this.selectTarget).select2({
      placeholder: "Search and select a matter",
      width: "100%",
      allowClear: true,
      ajax: {
        url: "/matters/search", // Rails server-side endpoint
        dataType: "json",
        delay: 250, // Delay for debouncing user input
        transport: function (params, success, failure) {
          const term = params.data.term || ""; // Default to an empty string for initial load
          const page = params.data.page || 0;

          // Check if results are cached for this term and page
          const cacheKey = `${term}-${page}`;
          if (self.cache[cacheKey]) {
            // Return cached results if available
            success(self.cache[cacheKey]);
            return;
          }

          // Make the actual AJAX request if not cached
          $.ajax(params)
            .done(function (data) {
              // Store the results in the cache
              self.cache[cacheKey] = data;
              success(data);
            })
            .fail(failure);
        },
        data: function (params) {
          return {
            term: params.term || "", // Empty term for initial load
            page: params.page || 0, // Pagination for lazy loading
          };
        },
        processResults: function (data, params) {
          // Increment the page number for subsequent requests
          params.page = params.page || 0;

          return {
            results: data.results.map(function (item) {
              return {
                id: item.id,         // Matter ID
                text: item.text,     // Matter title
                client_ids: item.client_ids // Array of client IDs
              };
            }),
            pagination: {
              more: data.pagination.more, // If more results are available
            },
          };
        },
        cache: false, // Disable default Select2 cache, since we're handling it
      },
      minimumInputLength: 0, // Allow showing options without typing
      dropdownParent: $(this.selectTarget).parent(), // Ensure the dropdown is within the target
    });

    $(this.selectTarget).on('select2:open', function () {
      $('.select2-search__field').attr('placeholder', 'Type here');
    });
  }

  disconnect() {
    // Destroy Select2 when the controller is disconnected
    $(this.selectTarget).select2("destroy");
  }
}
