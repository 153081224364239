import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['codeHiddenField', 'codeInput', 'submit']

  buildCodeFromInputs() {
    this.codeHiddenFieldTarget.value = this.insertedCode()

    return true
  }

  focusNextInput(event) {
    if(event.data && event.data.length > 0) {
      event.target.nextElementSibling?.focus()
    }
  }

  enableSubmitIfPossible() {
    this.submitTarget.disabled = this.isAnyInputEmpty()
  }

  insertedCode() {
    return this.codeInputTargets.map(input => input.value).join('')
  }

  isAnyInputEmpty() {
    return this.codeInputTargets.some(input => input.value.length === 0)
  }
}
