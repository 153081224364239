import StepModalController from "./step_modal_controller"
import Emitter from "./events/emitter"

export default class extends StepModalController {
  static targets = ['requestForm', 'requestFormInput', 'optInForm', 'optOutForm', 'confirmSettlementForm']

  initialize() {
    super.initialize()
    this.showFirstView()
    this.rawRequestValue = null
  }

  requestFormInputTargetConnected() {
    Emitter.on('currencyInputChanged', e => this.updateRequest(e))
  }

  updateRequest({ newRequestValueFormatted, newRequestValue }) {
    this.requestFormInputTarget.value = newRequestValueFormatted
    this.rawRequestValue = newRequestValue
  }

  submit(event) {
    event.preventDefault()
    this.swapInputValueToRaw()
    Rails.fire(this.requestFormTarget, 'submit')
  }

  submitOptIn(event) {
    event.preventDefault()
    this.moveToViewByName('confirmed-opt-in')
    Rails.fire(this.optInFormTarget, 'submit')
  }

  submitOptOut(event) {
    event.preventDefault()
    this.moveToViewByName('it-takes-two-before-algorithm')
    Rails.fire(this.optOutFormTarget, 'submit')
  }

  submitSettlementConfirmation(event) {
    event.preventDefault()
    this.moveToViewByName('confirmed-algorithm')
    Rails.fire(this.confirmSettlementFormTarget, 'submit')
  }

  submitSettlementDecline(event) {
    event.preventDefault()
    this.moveToViewByName('it-takes-two')
  }

  swapInputValueToRaw() {
    this.requestFormInputTarget.value = this.rawRequestValue
  }
}
