import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input', 'submit']

  enableSubmitIfPossible(event) {
    this.submitTarget.disabled = this.isAnyInputEmpty()
  }

  isAnyInputEmpty() {
    return this.inputTargets.some(input => input.value.length === 0)
  }
}
