import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['submit']

  enableSubmitIfPossible(event) {
    this.filterNonNumeric(event)
    const isInputEmpty = event.target.value.length === 0
    this.submitTarget.disabled = isInputEmpty
  }

  filterNonNumeric(event) {
    const input = event.target
    const sanitizedValue = input.value.replace(/\D/g, '')
    input.value = sanitizedValue
  }
}
