import ModalController from "./modal_controller"

export default class extends ModalController {
  static targets = ['view']

  showFirstView() {
    this.currentViewIndex = 0
    this.showCurrentView()
  }

  moveToNextView() {
    this.moveToView(this.currentViewIndex + 1)
  }

  moveToPreviousView() {
    this.moveToView(this.currentViewIndex - 1)
  }

  moveToView(newViewIndex) {
    this.hideCurrentView()
    this.currentViewIndex = newViewIndex
    this.showCurrentView()
  }

  moveToViewByName(name) {
    this.moveToView(this.viewTargetsNames().indexOf(name))
  }

  hideCurrentView() {
    this.viewTargets[this.currentViewIndex].classList.remove('active')
  }

  showCurrentView() {
    this.viewTargets[this.currentViewIndex].classList.add('active')
  }

  hideLastView() {
    this.viewTargets[this.currentViewIndex].classList.remove('active')
    this.showFirstView()
  }

  viewTargetsNames() {
    return this.viewTargets.map(element => element.dataset.viewName)
  }
}
