import { Controller } from "stimulus"
import Emitter from "./events/emitter";

export default class extends Controller {
  static targets = ['errorMessage']

  static values = {
    previousRequest: Number,
    requestType: String,
    currentRequest: Number
  }

  valueValidations = {
    offer: {
      isValid: () => {
        return this.currentRequestValue && this.currentRequestValue > this.previousRequestValue && this.currentRequestValue < this.initialDemandValue
      }
    },
    demand: {
      isValid: () => {
        return this.currentRequestValue && (this.currentRequestValue < this.previousRequestValue && this.currentRequestValue > this.initialOfferValue)
      }
    }
  }

  connect() {
    Emitter.on('sliderValueChanged', ({ newRequestValue }) => {
      this.currentRequestValue = newRequestValue
    })

    Emitter.on('currencyInputChanged', ({ newRequestValue }) => {
      this.currentRequestValue = newRequestValue
    })

    Emitter.on('initialDemandValue', ({ initialDemandValue }) => {
      this.initialDemandValue = initialDemandValue
    })

    Emitter.on('initialOfferValue', ({ initialOfferValue }) => {
      this.initialOfferValue = initialOfferValue
    })
  }

  valueValidation() {
    return this.valueValidations[this.requestTypeValue]
  }

  validateValue(event) {
    if (!this.valueValidation().isValid()) {
      event.stopImmediatePropagation()
      this.errorMessageTarget.classList.add("active")
    } else {
      this.errorMessageTarget.classList.remove("active")
    }
  }
}
