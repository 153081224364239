import { Controller } from "stimulus";
import Cleave from 'cleave.js';
import Emitter from "./events/emitter"

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.formatter = new Cleave(this.inputTarget, {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralPositiveOnly: true,
      numeralDecimalScale: 0,
      prefix: '$',
      noImmediatePrefix: true,
      rawValueTrimPrefix: true,
      onValueChanged: ({ target: { value, rawValue }}) => this.notifyChange(value, rawValue)
    })

    // Note: setRawValue triggers onValueChanged callback so "currencyInputChanged" will be emitted as well.
    Emitter.on('sliderValueChanged', ({ newRequestValue }) => this.formatter.setRawValue(newRequestValue))
  }

  notifyChange(formattedInputValue, rawInputValue) {
    Emitter.emit('currencyInputChanged', {
      newRequestValueFormatted: formattedInputValue,
      newRequestValue: rawInputValue
    })
  }
}
