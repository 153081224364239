import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['documentItem']

  toggleActive(event) {
    this.documentItemTargets.forEach((documentItem) => {
      if (documentItem.classList.contains("active")) {
        documentItem.classList.remove("active")
      }
    })

    const documentItem = this.documentItemTargets.find((target) => {
      return target.dataset.documentValue == event.currentTarget.dataset.id
    })

    documentItem.classList.add("active")
  }
}
