import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['fileInput', 'fileDetails']

  connect() {}

  showFileDetails(event) {
    const [file] = event.target.files
    const { name: fileName, size: fileSize } = file

    this.fileDetailsTarget.querySelector('.upload-filename').textContent = fileName
    this.fileDetailsTarget.querySelector('.upload-filesize').textContent = this.readableFileSize(fileSize)
    this.fileDetailsTarget.classList.remove('hidden')
  }

  hideFileDetails() {
    this.fileDetailsTarget.classList.add('hidden')
  }

  clearInput() {
    this.fileInputTarget.value = null
  }

  readableFileSize(originalFileSize) {
    return (originalFileSize < 1000000) ? `${(originalFileSize / 1000).toFixed(2)} KB` : `${(originalFileSize / 1000000).toFixed(2)} MB`
  }
}
