import StepModalController from "./step_modal_controller"

export default class extends StepModalController {
  static targets = [
    'requestForm',
    'wageLossDocumentationComment',
    'medicalRecordComment',
    'policeReportComment',
    'otherComment',
  ]

  connect() {
    super.initialize()
    this.showFirstView()
  }

  submit(event) {
    event.preventDefault()
    Rails.fire(this.requestFormTarget, 'submit')
    this.requestFormTarget.reset()
    this.moveToNextView()
  }

  displayComments(event) {
    const categoryValue = event.currentTarget.dataset.categoryValue
    const categoryTarget = this[`${categoryValue}CommentTarget`]
    const newDisabledValue = !categoryTarget.disabled
    categoryTarget.disabled = newDisabledValue
    this[`${categoryValue}CommentTarget`].parentElement.classList.toggle("hidden")
  }
}
