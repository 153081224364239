import Rails from "@rails/ujs";
import { Controller } from "stimulus";
import { flatfileImporter } from "@flatfile/sdk";

export default class extends Controller {
  /*
    In case we need other callbacks, the importer offers these ones:

    this.importer.on('init', ({ batchId }) => {})
    this.importer.on('launch', ({ batchId }) => {})
    this.importer.on('error', (error) => {})
  */

  static values = {
    token: String,
    postUrl: String,
    callbackUrl: String
  }

  async connect() {
    this.importer = flatfileImporter(this.tokenValue)

    this.importer.on('complete', async (payload) => {
      const response = await payload.data()
      const validClaims = this.findValidClaimsOn(response)

      this.bulkUploadClaims(validClaims)
    })
  }

  async openImporter() {
    await this.importer.launch()
  }

  findValidClaimsOn(response) {
    const claims = response.rows
    const validClaims = claims.filter(claim => claim.valid)

    return validClaims.map(validClaim => validClaim.data)
  }

  bulkUploadClaims(claims) {
    fetch(this.postUrlValue, {
      method: 'post',
      body: JSON.stringify(claims),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken()
      },
      credentials: 'same-origin'
    }).then((response) => {
      window.location = this.callbackUrlValue
    });
  }
}
