import { Controller } from "stimulus";
import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker";

export default class extends Controller {
    static targets = ["datepickerInput"];

    connect() {
        $(this.datepickerInputTarget).datepicker({
            dateFormat: "mm/dd/yy",
            maxDate: 0,
            changeYear: true,
            changeMonth: true,
            yearRange: "-1000:+100",
        });

        // Prevent typing in the input field
        $(this.datepickerInputTarget).on("keydown", (event) => {
            event.preventDefault();
        });

        $(".icon").on("click", () => {
            $(this.datepickerInputTarget).datepicker("show");
        });
    }
}