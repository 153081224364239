import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input', 'icon']

  hideOrRevealInput(event) {
    event.preventDefault()
    this.isHidingPassword() ? this.showPassword() : this.hidePassword()
  }

  isHidingPassword() {
    return this.inputTarget.getAttribute('type') === 'password'
  }

  hidePassword() {
    this.updateComponent('password', 'visibility', 'Show Password')
  }

  showPassword() {
    this.updateComponent('text', 'visibility_off', 'Hide Password')
  }

  updateComponent(inputType, iconName, iconAriaLabel) {
    this.inputTarget.setAttribute('type', inputType)
    this.iconTarget.innerText = iconName
    this.iconTarget.setAttribute('aria-label', iconAriaLabel)
  }
}
