import { Controller } from "stimulus";

export default class extends Controller {
  static targets =['agreeButton', 'agreeCheckbox']

  toggleSubmitButton() {
    const checkbox = this.agreeCheckboxTarget
    const button = this.agreeButtonTarget

    button.disabled = !checkbox.checked
  }
}