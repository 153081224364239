import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "filterTab",
    "searchClaimForm",
    "all",
    "algorithmStage",
    "finalSubmissions",
    "submissionNeeded",
    "newUpdates",
    "actionNeeded",
    "invitationPending",
    "settled",
    "inactive",
    "didntSettle",
    "allBlock",
    "finalSubmissionsBlock",
    "submissionNeededBlock",
  ];

  submit() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.searchClaimFormTarget.requestSubmit();
    }, 300); // debounce to avoid too many requests
  }

  toggleActive(e) {
    this.filterTabTargets.forEach((filterTab) => {
      if (filterTab.classList.contains("active")) {
        filterTab.classList.remove("active");
      }
    });
    const filterValue = e.currentTarget.dataset.filterValue;
    this[`${filterValue}Target`].classList.add("active");

    // Clear the input query on tab change.
    const inputElement = this.filterTabTargets.find(
      (element) => element.tagName.toLowerCase() === "input"
    );
    if (filterValue !== "all") {
      inputElement.value = "";
    } else if (inputElement.value.length > 0 && filterValue === "all") {
      e.preventDefault();
    }

    const capitalizedFilterValue =
      filterValue.charAt(0).toUpperCase() + filterValue.substring(1);
    if (this[`has${capitalizedFilterValue}BlockTarget`]) {
      this[`${filterValue}BlockTarget`].classList.add("active");
    }
  }
}
