import StepModalController from "./step_modal_controller"

export default class extends StepModalController {
  static targets = ['requestForm']

  connect() {
    super.initialize()
    this.maxFileSize = 5368709120
    this.fileSizeError = 'Please select a file smaller than 5 GB'
    this.showFirstView()
  }

  submit(event) {
    event.preventDefault()
    const isValid = this.validateForm()

    if (isValid) {
      this.requestFormTarget.requestSubmit()
      this.requestFormTarget.reset()
      this.moveToNextView()
    }
  }

  validateForm() {
    const requiredFieldSelectors = 'input:required, select:required'
    const requiredFields = this.requestFormTarget.querySelectorAll(requiredFieldSelectors)
    const invalidFields = []

    requiredFields.forEach((field) => {
      invalidFields.push(this.validateFileSize(field))
      invalidFields.push(this.validatePresence(field))
    })

    return invalidFields.filter(field => field).length == 0
  }

  validateFileSize(field) {
    if (field.files.length > 0) {
      const [file] = field.files
      const { size: fileSize } = file
      if(fileSize > this.maxFileSize) {
        const fileSizeErrorElement = document.getElementById(field.className)
        fileSizeErrorElement.textContent = this.fileSizeError
        fileSizeErrorElement.classList.add('active')
        return field
      }
    }
  }

  validatePresence(field) {
    if (!field.disabled && !field.value.trim()) {
      document.getElementById(field.className).classList.add('active')
      field.focus()
      return field
    }
  }
}
