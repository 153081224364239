import StepModalController from "./step_modal_controller"

export default class extends StepModalController {
    static targets = ['requestForm', 'fileDetails']

    connect() {

        super.initialize()
        this.showFirstView()
    }

    submit(event) {
        event.preventDefault()
        const isValid = this.validateForm()

        if (isValid) {
            this.requestFormTarget.requestSubmit()
            this.requestFormTarget.reset()
            this.moveToNextView()
        }
    }

    validateForm() {
        // const requiredFieldSelectors = 'input:required, select:required'
        const requiredFields = this.requestFormTarget.querySelectorAll('select')
        const invalidFields = []

        requiredFields.forEach((field) => {
            invalidFields.push(this.validatePresence(field))
        })

        return invalidFields.filter(field => field).length == 0
    }

    validatePresence(field) {
        if (!field.disabled && !field.value.trim()) {
            document.getElementById(field.className).classList.add('active')
            field.focus()
            return field
        }else if(!field.disabled && field.value.trim()){
            document.getElementById(field.className).classList.remove('active')
        }
    }

    hideFileDetails() {
        this.fileDetailsTarget.classList.add('hidden')
    }
}
