// The idea of this classes is to help with positioning stuff properly depending on the Request type.
// This is needed because we want the slider to always go from left to right, and in order to achieve this we're setting
// the "direction" property on the slider:
//
// - For Offers, the direction is Left to Right (the default of the input component)
// - For Demands, the direction is Right to Left
//
// This makes us need to do some opposite calculations depending the request type.

export default class SliderInputType {
  static for(requestType) {
    const sliderClass = this.subclasses().find(slider => slider.for(requestType))

    return new sliderClass()
  }

  static subclasses() {
    return [DemandSliderInputType, OfferSliderInputType]
  }

  thumbSize() {
    return 22
  }

  halfThumbSize() {
    return this.thumbSize() / 2
  }

  offsetInPxFor(offsetPercentage, minRange, maxRange) {
    const range = maxRange - minRange
    const offsetPx = (offsetPercentage * range / 100) + minRange

    return { offsetSide: this.offsetSide() , offsetPx }
  }

  buildTooltipStatus(tooltipMessage, hasReachedMilestone) {
    return { tooltipMessage, hasReachedMilestone }
  }

  offsetSide() {
    throw 'Subclass Responsibility'
  }

  lastRequest() {
    throw 'Subclass Responsibility'
  }

  hasValuePassedLastRequest() {
    throw 'Subclass Responsibility'
  }

  offsetPxForPreviousRequest() {
    throw 'Subclass Responsibility'
  }

  offsetPxForCurrentRound() {
    throw 'Subclass Responsibility'
  }

  offsetPxForTooltip() {
    throw 'Subclass Responsibility'
  }

  tooltipStatusBasedOnMilestone() {
    throw 'Subclass Responsibility'
  }

  gradientMilestoneStopFor() {
    throw 'Subclass Responsibility'
  }
}

class OfferSliderInputType extends SliderInputType {
  static for(requestType) {
    return requestType === 'offer'
  }

  offsetSide() {
    return 'left'
  }

  lastRequest(previousRequests) {
    return Math.max(...previousRequests)
  }

  hasValuePassedLastRequest(currentValue, lastRequest) {
    return currentValue <= lastRequest
  }

  offsetPxForPreviousRequest(offsetPercentage) {
    return super.offsetInPxFor(offsetPercentage, 0, this.thumbSize())
  }

  offsetPxForCurrentRound(offsetPercentage) {
    return super.offsetInPxFor(offsetPercentage, 0, -this.thumbSize())
  }

  offsetPxForTooltip(offsetPercentage) {
    return super.offsetInPxFor(offsetPercentage, -this.halfThumbSize(), this.halfThumbSize())
  }

  tooltipStatusBasedOnMilestone(currentValue, nextMilestoneValue) {
    const milestoneMin = nextMilestoneValue - (nextMilestoneValue * 0.1)
    const milestoneMax = nextMilestoneValue + (nextMilestoneValue * 0.1)

    if(currentValue < milestoneMin) {
      return this.buildTooltipStatus('You need to increase your offer to reach a new milestone', false)
    } else if(currentValue >= milestoneMin && currentValue < nextMilestoneValue) {
      return this.buildTooltipStatus("You're close to reaching your next milestone", false)
    } else if(currentValue >= nextMilestoneValue && currentValue < milestoneMax) {
      return this.buildTooltipStatus('You have reached your milestone. Go further to increase your chances of settling the case', true)
    } else if(currentValue >= milestoneMax) {
      return this.buildTooltipStatus("You've gone past a milestone. This is an excellent offer!", true)
    }
  }

  gradientMilestoneStopFor(milestoneAsPercentageForSlider) {
    return milestoneAsPercentageForSlider
  }
}

class DemandSliderInputType extends SliderInputType {
  static for(requestType) {
    return requestType === 'demand'
  }

  offsetSide() {
    return 'right'
  }

  lastRequest(previousRequests) {
    return Math.min(...previousRequests)
  }

  hasValuePassedLastRequest(currentValue, lastRequest) {
    return currentValue >= lastRequest
  }

  offsetPxForPreviousRequest(offsetPercentage) {
    return super.offsetInPxFor(offsetPercentage, -this.thumbSize(), 0)
  }

  offsetPxForCurrentRound(offsetPercentage) {
    return super.offsetInPxFor(offsetPercentage, this.thumbSize(), 0)
  }

  offsetPxForTooltip(offsetPercentage) {
    return super.offsetInPxFor(offsetPercentage, -this.halfThumbSize(), this.halfThumbSize())
  }

  tooltipStatusBasedOnMilestone(currentValue, nextMilestoneValue) {
    const milestoneMin = nextMilestoneValue + (nextMilestoneValue * 0.1)
    const milestoneMax = nextMilestoneValue - (nextMilestoneValue * 0.1)

    if(currentValue > milestoneMin) {
      return this.buildTooltipStatus('You need to decrease your demand to reach a new milestone', false)
    } else if(currentValue <= milestoneMin && currentValue > nextMilestoneValue) {
      return this.buildTooltipStatus("You're close to reaching your next milestone", false)
    } else if(currentValue <= nextMilestoneValue && currentValue > milestoneMax) {
      return this.buildTooltipStatus('You have reached your milestone. Go further to increase your chances of settling the case', true)
    } else if(currentValue <= milestoneMax) {
      return this.buildTooltipStatus("You've gone past a milestone. This is an excellent demand!", true)
    }
  }

  gradientMilestoneStopFor(milestoneAsPercentageForSlider) {
    return 100 - milestoneAsPercentageForSlider
  }
}
