import { Spinner } from 'spin.js';

let spinnerInstance = null;

export function showSpinner() {
    if (spinnerInstance) return; // Prevent multiple spinners from being created

    const opts = {
        lines: 12,
        length: 7,
        width: 5,
        radius: 10,
        scale: 1,
        corners: 1,
        color: "#000",
        speed: 1,
        trail: 60,
        top: "50%",
        left: "50%",
        position: "fixed",
    };

    const target = document.createElement("div");
    target.setAttribute("id", "global-spinner");
    target.style.position = "fixed";
    target.style.top = "0";
    target.style.left = "0";
    target.style.width = "100%";
    target.style.height = "100%";
    target.style.zIndex = "1000";
    target.style.backgroundColor = "rgba(255, 255, 255, 0.8)"; // Optional overlay
    document.body.appendChild(target);

    spinnerInstance = new Spinner(opts).spin(target);
}

export function hideSpinner() {
    if (spinnerInstance) {
        spinnerInstance.stop();
        document.getElementById("global-spinner").remove();
        spinnerInstance = null;
    }
}