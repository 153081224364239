import { Controller } from "@hotwired/stimulus";
import introJs from "intro.js";

export default class extends Controller {
  connect() {
    const currentPath = window.location.pathname;
    const pattern = /^\/claims\/\d+$/;

    if (
      currentPath === "/" ||
      currentPath === "/dashboard" ||
      pattern.test(currentPath)
    )
      this.checkPathAndStartTutorial(currentPath);
  }

  async checkPathAndStartTutorial(currentPath) {
    const response = await fetch(
      `/check_intro_status?path=${encodeURIComponent(currentPath)}`
    );

    const data = await response.json();

    if (data.showIntro) {
      this.startTutorial(this.getTutorialSteps(data.introKey), data.introKey);
    }
  }

  getTutorialSteps(path) {
    if (path === "dashboard") {
      return this.dashboardGuide();
    } else if (path === "claim") {
      return this.claimGuide();
    }
    return [];
  }

  async startTutorial(steps, introKey) {
    const intro = introJs();
    intro.setOptions({ steps, doneLabel: "Close" });

    intro
      .oncomplete(async () => {
        await this.markIntroSeen(introKey);
      })
      .onbeforeexit(async () => {
        await this.markIntroSeen(introKey);
      });

    intro.start();
  }

  async markIntroSeen(introKey) {
    const dontShowAgainButton = document.getElementById(
      "intro_dont_show_again"
    );
    if (dontShowAgainButton && dontShowAgainButton.checked) {
      await fetch(`/mark_intro_seen`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
            .content,
        },
        body: JSON.stringify({ introKey: introKey }),
      });
    }
  }

  dashboardGuide() {
    const dashboardGuides = [
      {
        intro: `<div class="intro-main">
                  <div class="text-content">
                    <p>Welcome to the Resolutn dashboard!</p>
                    <p>Let's take a quick tour.</p>
                  </div>                    
                  <div class="dont-show-again">
                    <input type="checkbox" id="intro_dont_show_again"> 
                    <label for="intro_dont_show_again">Don't Show Again on this Page</label>
                  </div>
                </div>`,
      },
      {
        element: "#intro_step_header_add_claim",
        intro: `<div class="intro-main">
                  <div class="text-content">
                    <p>Add a new claim by clicking this button</p>
                  </div>                    
                  <div class="dont-show-again">
                    <input type="checkbox" id="intro_dont_show_again"> 
                    <label for="intro_dont_show_again">Don't Show Again on this Page</label>
                  </div>
                </div>`,
        position: "bottom",
      },
      {
        element: "#intro_step_section_claim_filters",
        intro: `<div class="intro-main">
                  <div class="text-content">
                    <p>Filter the claims by status</p>
                  </div>                    
                  <div class="dont-show-again">
                    <input type="checkbox" id="intro_dont_show_again"> 
                    <label for="intro_dont_show_again">Don't Show Again on this Page</label>
                  </div>
                </div>`,
        position: "bottom",
      },
    ];

    if (
      document.querySelectorAll("table tbody td:first-of-type a").length > 0
    ) {
      dashboardGuides.push(
        {
          element: "#claims",
          intro: `<div class="intro-main">
                  <div class="text-content">
                    <p>List of created claims by you and opposing party</p>
                  </div>                    
                  <div class="dont-show-again">
                    <input type="checkbox" id="intro_dont_show_again"> 
                    <label for="intro_dont_show_again">Don't Show Again on this Page</label>
                  </div>
                </div>`,
          position: "bottom",
        },
        {
          element: "table td:first-of-type a",
          intro: `<div class="intro-main">
                  <div class="text-content">
                    <p>Click on the specific claim number for negotiation</p>
                  </div>                    
                  <div class="dont-show-again">
                    <input type="checkbox" id="intro_dont_show_again"> 
                    <label for="intro_dont_show_again">Don't Show Again on this Page</label>
                  </div>
                </div>`,
          position: "bottom",
        }
      );
    }

    return dashboardGuides;
  }

  claimGuide() {
    return [
      {
        element: "#intro_step_negotiation_button",
        intro: `<div class="intro-main">
                  <div class="text-content">
                    <p>Click on this button for negotiation</p>
                  </div>                    
                  <div class="dont-show-again">
                    <input type="checkbox" id="intro_dont_show_again"> 
                    <label for="intro_dont_show_again">Don't Show Again on this Page</label>
                  </div>
                </div>`,
        position: "top",
      },
      {
        element: "#intro_step_header_share_claim",
        intro: `<div class="intro-main">
                  <div class="text-content">
                    <p>Share a claim by clicking this button</p>
                  </div>                    
                  <div class="dont-show-again">
                    <input type="checkbox" id="intro_dont_show_again"> 
                    <label for="intro_dont_show_again">Don't Show Again on this Page</label>
                  </div>
                </div>`,
        position: "bottom",
      },
      {
        element: "#intro_step_section_documents",
        intro: `<div class="intro-main">
                  <div class="text-content">
                    <p>Here you can share documents with the other side</p>
                  </div>                    
                  <div class="dont-show-again">
                    <input type="checkbox" id="intro_dont_show_again"> 
                    <label for="intro_dont_show_again">Don't Show Again on this Page</label>
                  </div>
                </div>`,
        position: "top",
      },
      {
        element: "#intro_step_section_messages",
        intro: `<div class="intro-main">
                  <div class="text-content">
                    <p>Here you can chat with the opposing party, also you can download the chat history</p>
                  </div>                    
                  <div class="dont-show-again">
                    <input type="checkbox" id="intro_dont_show_again"> 
                    <label for="intro_dont_show_again">Don't Show Again on this Page</label>
                  </div>
                </div>`,
        position: "top",
      },
    ];
  }
}
