import StepModalController from "./step_modal_controller"

export default class extends StepModalController {
  static targets = ['requestForm']

  connect() {
    super.initialize()
    this.showFirstView()
  }

  submit(event) {
    event.preventDefault()
    const isValid = this.validateForm(this.requestFormTarget)

    if (isValid) {
      Rails.fire(this.requestFormTarget, 'submit')
      this.requestFormTarget.reset()
      this.moveToNextView()
    }
  }

  validateForm() {
    const requiredFieldSelectors = 'input:required, select:required'
    const requiredFields = this.requestFormTarget.querySelectorAll(requiredFieldSelectors)
    const invalidFields = []

    requiredFields.forEach((field) => {
      if (!field.disabled && !field.value.trim()) {
        document.getElementById(field.className).classList.add('active')
        field.focus()
        invalidFields.push(field)
      }
    })

    return invalidFields.length == 0
  }
}
