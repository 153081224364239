import Rails from "@rails/ujs";
import { Controller } from "stimulus";
import $ from "jquery";
import "select2";
import {showSpinner, hideSpinner} from "../spinner";

export default class extends Controller {
  static values = { index: Number }
  static targets = [
    "page",
    "chooseMatter",
    "mattersListing",
    "step1",
    "step2",
    // "step3",
    // "documentUpload",
    "newClaimForm",
    "forwardButton",
    "submitButton",
    "backButton",
    "stepCircle",
    "stepConnector",
    // "documentCheckbox",
    // "wageLossDocumentationCheckbox",
    // "medicalRecordCheckbox",
    // "policeReportCheckbox",
    // "otherCheckbox",
    // "documentUploader",
    // "wageLossDocumentationUploader",
    // "medicalRecordUploader",
    // "policeReportUploader",
    // "otherUploader",
    // "wageLossDocumentationUploaderFieldsGroup",
    // "medicalRecordUploaderFieldsGroup",
    // "policeReportUploaderFieldsGroup",
    // "otherUploaderFieldsGroup",
    // "wageLossDocumentationThirdPartyUploaderFieldsGroup",
    // "medicalRecordThirdPartyUploaderFieldsGroup",
    // "policeReportThirdPartyUploaderFieldsGroup",
    // "otherThirdPartyUploaderFieldsGroup",
    // "documentUploadFieldGroup",
    // "uploaderFieldsGroup"
  ]

  initialize() {
    // this.documentUploaderTargets.forEach((element, index) => {
    //   var uploaderFieldsGroup = element.querySelector(".document-upload-fields")
    //
    //   this.renderDocumentUploader(element)
    // })
    if(this.hasChooseMatterTarget){
      this.allowMatterAttachment = this.chooseMatterTarget ? true : false
    }

    this.showPage(this.indexValue)
    this.maxFileSize = 5368709120
  }

  pageIndexCatalog(){
    if(this.allowMatterAttachment){
      return  {
        0: this.chooseMatterTarget,
        1: this.step1Target,
        2: this.step2Target,
        3: this.step3Target,
        4: this.documentUploadTarget,
      }
    }else{
      return  {
        0: this.step1Target,
        1: this.step2Target,
        2: this.step3Target,
        3: this.documentUploadTarget,
      }
    }
  }

  inputValidations = {
    "text": this.validatePresenceOn.bind(this),
    "date": this.validatePresenceOn.bind(this),
    "select-one": this.validatePresenceOn.bind(this),
    "email": this.validateEmailInput.bind(this),
    "file": this.validateFileInput.bind(this)
  }

  currentPage() {
    return this.pageIndexCatalog()[this.indexValue]
  }

  forward(event) {
    event.preventDefault()

    const isValid = this.validate(this.currentPageRequiredInputs())

    if(isValid) {
      this.hideElement(this.currentPage())
      this.indexValue++
      // Prefill the data from selected matter.
      if(this.allowMatterAttachment) this.prefillClaimData()
      this.showPage(this.indexValue)
    }
  }

  previous(event) {
    event.preventDefault()
    this.hideElement(this.currentPage())
    this.indexValue--
    this.showPage(this.indexValue)
  }

  prefillClaimData(){
    if(this.indexValue === 1){
      const selectedData = $(this.mattersListingTarget).select2('data'); // This gets the selected data

      document.getElementById("claim_claimant_first_name").value = ""
      document.getElementById("claim_claimant_last_name").value = ""

      if (selectedData.length > 0) {
        showSpinner();
        const selectedOption = selectedData[0]; // Assuming single select, get the first option
        const clientIds = selectedOption.client_ids; // Access client_ids from the selected option
        //   Call the ajax to fetch the data
        fetch(`/claims/fetch_third_party_client?client_id=${clientIds[0]}`, {
          // Uncomment this if you're making a POST request
          // method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': Rails.csrfToken() // Ensure Rails authenticity token is included
          },
          credentials: 'same-origin'
        })
            .then((response) => {
              if (!response.ok) {
                throw new Error('Network response was not ok'); // Error handling for non-200 responses
              }
              return response.json(); // Return the parsed JSON response
            })
            .then((data) => {
              document.getElementById("claim_claimant_first_name").value = data.first_name
              document.getElementById("claim_claimant_last_name").value = data.last_name
              console.log(data); // Handle your data here
              // window.location = this.callbackUrlValue; // Uncomment if you want to redirect
            })
            .catch((error) => {
              console.error('There was a problem with the fetch operation:', error);
            }).finally(() => {
          hideSpinner();
        });
      }
    }
  }

  showPage(pageIndex) {
    var targetPage = this.pageIndexCatalog()[pageIndex]
    this.showElement(targetPage)
    this.backButtonTarget.hidden = (this.indexValue == 0);
    this.renderForwardButton()
    this.setSubmitButtonText()
    this.renderStepProgressCircles()
  }

  renderStepProgressCircles() {
    this.stepCircleTargets.forEach((element, index) => {
      if (index <= this.indexValue) {
        element.classList.add("active")
      } else {
        element.classList.remove("active")
      }
    })

    this.stepConnectorTargets.forEach((element, index) => {
      this.activateStepConnector(element, index)
    })
  }

  activateStepConnector(element, index) {
    if (index < this.indexValue) {
      element.classList.add("active")
    } else {
      element.classList.remove("active")
    }
  }
  
  setSubmitButtonText() {
    if (this.isStep3Page()) {
      this.submitButtonTarget.value = "Skip - Add Claim"
    } else {
      this.submitButtonTarget.value = "Create Claim"
    }
  }

  renderForwardButton() {
    if (this.shouldSubmit()) {
      this.forwardButtonTarget.hidden = true
      this.submitButtonTarget.hidden = false
      this.submitButtonTarget.disabled = false
    } else {
      this.forwardButtonTarget.hidden = false
      this.submitButtonTarget.hidden = true
    }
  }

  shouldSubmit() {
    return (
        this.isStep2Page()
      // (this.isLastPage()) || (this.isStep3Page() && this.isSkippingDocuments())
    )
  }

  isLastPage() {
    return this.currentPage() == (this.pageTargets[this.pageTargets.length - 1])
  }

  isStep2Page() {
    return (this.currentPage() == this.step2Target)
  }


  isStep3Page() {
    return (this.currentPage() == this.step3Target)
  }

  submitForm(event) {
    event.preventDefault()
    const isValid = this.validate(this.currentPageRequiredInputs())
    // const isValid = this.validate(this.documentInputs())
    if(isValid) {
      Rails.fire(this.newClaimFormTarget, 'submit')
    }
  }

  debug() {
  }

  isSkippingDocuments() {
    return this.documentCheckboxTargets.every(function (checkbox) { return checkbox.checked === false })
  }

  uploaders = {
    "wageLossDocumentationUploader": this.wageLossDocumentationUploaderTarget,
    "medicalRecordUploader": this.medicalRecordUploaderTarget,
    "policeReportUploader": this.policeReportUploaderTarget,
    "otherUploader": this.otherUploaderTarget
  }

  setUploaderShouldDisplay(event) {
    const uploaderTarget = this.uploaders[event.params["uploader"]]
    uploaderTarget.querySelector(".should_display_hidden_field").value = event.target.checked
    this.renderForwardButton()
    this.renderDocumentUploader(uploaderTarget)
  }

  renderDocumentUploader(uploaderElement) {
    const shouldShowUploader = uploaderElement.querySelector(".should_display_hidden_field").value === "true";
    if (shouldShowUploader) {
      this.showElement(uploaderElement)
    } else {
      this.hideElement(uploaderElement)
    }
  }

  toggleShow(element) {
    element.classList.toggle("show")
  }

  uploaderFieldsGroups = {
    "wageLossDocumentation": this.wageLossDocumentationUploaderFieldsGroupTarget,
    "medicalRecord": this.medicalRecordUploaderFieldsGroupTarget,
    "policeReport": this.policeReportUploaderFieldsGroupTarget,
    "other": this.otherUploaderFieldsGroupTarget
  }

  selectUploadOrRequest(event) {
    if(this.hasWageLossDocumentationThirdPartyUploaderFieldsGroupTarget){
      this.uploaderFieldsGroups["wageLossDocumentationThirdParty"] = this.wageLossDocumentationThirdPartyUploaderFieldsGroupTarget
    }

    if(this.hasMedicalRecordThirdPartyUploaderFieldsGroupTarget){
      this.uploaderFieldsGroups["medicalRecordThirdParty"] = this.medicalRecordThirdPartyUploaderFieldsGroupTarget
    }

    if(this.hasPoliceReportThirdPartyUploaderFieldsGroupTarget){
      this.uploaderFieldsGroups["policeReportThirdParty"] = this.policeReportThirdPartyUploaderFieldsGroupTarget
    }

    if(this.hasOtherThirdPartyUploaderFieldsGroupTarget){
      this.uploaderFieldsGroups["otherThirdParty"] = this.otherThirdPartyUploaderFieldsGroupTarget
    }

    const isRequested = (event.target.value === "true")
    const activeRadioButton = event.target
    let uploaderFieldsGroup;
    let ThirdPartyUploaderFieldsGroup;
    const [radioButton, thirdPartyRadioButton] = event.params["uploaderFieldsGroup"].split(' ')
      uploaderFieldsGroup = this.uploaderFieldsGroups[radioButton]
      ThirdPartyUploaderFieldsGroup = this.uploaderFieldsGroups[thirdPartyRadioButton]
    this.renderUploaderFieldsGroup(isRequested, uploaderFieldsGroup, ThirdPartyUploaderFieldsGroup, activeRadioButton)
  }

  renderUploaderFieldsGroup(shouldHide, uploaderFieldsGroup, ThirdPartyUploaderFieldsGroup, activeRadioButton) {
    if (shouldHide) {
      this.hideElement(uploaderFieldsGroup)
      this.hideElement(ThirdPartyUploaderFieldsGroup)
    } else {
      this.showElement(uploaderFieldsGroup)
      this.showElement(ThirdPartyUploaderFieldsGroup)
    }

    if(activeRadioButton.id.includes('third_party_documents')){
      activeRadioButton.value = false
      this.hideElement(uploaderFieldsGroup)
    }else{
      this.hideElement(ThirdPartyUploaderFieldsGroup)
    }
  }

  showUploadField(event) {
    this.showElement(event.target.parentElement.nextElementSibling)
  }
  hideUploadField(event) {
    this.hideElement(event.target.parentElement.nextElementSibling)
  }

  showElement(element) {
    if(element) element.classList.add("show")
  }
  hideElement(element) {
    if(element) element.classList.remove("show")
  }

  changeSubmitButton() {
    this.renderForwardButton()
  }

  currentPageRequiredInputs() {
    return [...this.currentPage().querySelectorAll('input[required], select[required]')]
  }

  documentInputs() {
    const selectedDocumentsUploaders = this.uploaderFieldsGroupTargets.filter(element => element.classList.contains('show'))

    return selectedDocumentsUploaders.map(documentUploader => documentUploader.querySelector('input[type="file"]') || documentUploader.querySelector('select') )
  }

  inputErrorMessage(input, errorType) {
    if(input.id === 'datepicker'){
      return input.parentElement.parentElement.querySelector(`.error[data-error-type="${errorType}"]`)
    }
    return input.parentElement.querySelector(`.error[data-error-type="${errorType}"]`)
  }

  allInputErrorMessages(input) {
    if(input.id === 'datepicker'){
      return [...input.parentElement.parentElement.querySelectorAll('.error.active')]
    }
    return [...input.parentElement.querySelectorAll('.error.active')]
  }

  showInputError(input, errorType) {
    this.inputErrorMessage(input, errorType).classList.add('active')
  }

  hideInputErrors(input) {
    this.allInputErrorMessages(input).forEach(error => error.classList.remove('active'))
  }

  validate(inputsToValidate) {
    const validationResults = inputsToValidate.map(input => this.inputValidations[input.type](input))

    return validationResults.every(validationResult => validationResult)
  }

  validatePresenceOn(input) {
    this.hideInputErrors(input)

    if(!input.value) {
      this.showInputError(input, 'presence')

      return false
    }

    //Validate format of mobile number
    if(input.name.includes('mobile_phone')){

      const regex = /^(?!.*\(\D*\().*(?!.*\)\D*\)).*^\(?\d+\)?[-.\s]?\d*[-.\s]?\d*$/;

      const mobile_number = input.value.trim();
      if (!regex.test(mobile_number)) {
        this.showInputError(input, 'format')
        return false
      }
    }

    return true
  }

  validateFileInput(input) {
    this.hideInputErrors(input)

    if(!input.value) {
      this.showInputError(input, 'presence')

      return false
    }

    const [file] = input.files
    const { size: fileSize } = file

    if(fileSize > this.maxFileSize) {
      this.showInputError(input, 'format')
      return false
    }

    return true
  }

  validateEmailInput(input) {
    this.hideInputErrors(input)

    if(!input.value) {
      this.showInputError(input, 'presence')
      return false
    }

    // Backend validation is way stronger. This is just to avoid the most basic emails.
    if(!/.+@.+\..+/.test(input.value)) {
      this.showInputError(input, 'format')
      return false
    }

    return true
  }
}
