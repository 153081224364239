import Rails from "@rails/ujs"
import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['leftNav']

  initialize() {
    this.setElementCollapseState()
  }
  
  toggleCollapse() {
    this.toggleCollapseState()
    this.setElementCollapseState()
  }

  toggleCollapseState() {
    localStorage.setItem("leftNavCollapseState", JSON.stringify(!this.getCollapseStateFromStorage()))
  }

  getCollapseStateFromStorage() {
    return JSON.parse(localStorage.getItem("leftNavCollapseState")) === true
  }
  
  setElementCollapseState() {
    if (this.getCollapseStateFromStorage()) {
      this.leftNavTarget.classList.add("collapsed")
    } else {
      this.leftNavTarget.classList.remove("collapsed")
    }
  }
}
